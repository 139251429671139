import React from "react";
import { useTheme } from "styled-components";

/* images */
import chatterImage from "../helper/image/chatter2.png";
import { Fontawesome } from "../helper/fontawesome/Fontawesome";
import { useRecoilState } from "recoil";

/* global states */
import { displayIndexDefault } from "../store/states";

export const Chatter = ({ handleScroll, displayRef }) => {
	/* mobile first */
	const { sm, md } = useTheme();

	const [displayIndex, setDisplayIndex] = useRecoilState(displayIndexDefault);

	const handleNavigate = () => {
		setDisplayIndex(0);
		handleScroll(displayRef);
	};

	const handleOtherProjectClick = () => {
		setDisplayIndex(0);
		handleScroll(displayRef);
	};

	return (
		<div
			className='h-auto w-full 
                        rounded-3xl
                        flex gap-2 flex-col justify-center items-center
                        '
		>
			<div
				className='ttt-header w-full
                            text-[18px] font-[600] bg-neutral-900 
                            flex justify-center items-center
                            rounded-3xl p-1 
							sticky top-0
                            '
			>
				<span
					onClick={handleNavigate}
					className='absolute left-5 cursor-pointer 
                                text-gray-500 hover:text-white
                                pr-3
                                '
				>
					<Fontawesome type='faArrowLeft' />
				</span>
				Chattter
			</div>
			<div
				className={`chatter-body bg-neutral-900 w-full pt-5 pb-5
                            flex flex-col justify-center items-center
							rounded-3xl
							${sm ? "px-3" : "px-10"}

                            `}
			>
				<div className='ttt-image flex gap-2 flex-col justify-center items-center'>
					<div
						className={` ${sm ? "w-[95%]" : "w-[90%]"}
									bg-neutral-800 rounded-3xl
                                    flex justify-between items-center px-4 py-1
                                    `}
					>
						<div className='github text-[12px] font-[500] text-blue-500 cursor-pointer'>
							<span className='pr-1'>
								<Fontawesome type={"faGithub"} />
							</span>
							<a
								className='font-[500] text-white hover:text-blue-500 
                                            transition-all ease-in-out
                                            '
								href='https://github.com/hanifahmadov/chatter'
								target='_blank'
							>
								Github
							</a>
						</div>

						<div className='github text-[12px] font-[500] text-blue-500 cursor-pointer'>
							<span className='pr-1'>
								<Fontawesome type={"faLink"} />
							</span>
							<a
								className='font-[500] text-white hover:text-blue-500 
                                            transition-all ease-in-out
                                            '
								href='https://chatter.hanifahmadov.site/welcome'
								target='_blank'
							>
								Demo
							</a>
						</div>
					</div>

					<div
						className={` ${sm ? "w-[95%]" : "w-[90%]"} 
									rounded-xl overflow-hidden
									`}
					>
						<img src={chatterImage} className='h-full w-full object-cover object-center' />
					</div>
				</div>

				<div className='ttt-text-content w-full mt-5'>
					<div>
						<div className='title text-[16px] text-slate-500 font-[600]'>Intro.</div>
						<div className='text-[14px] mt-1'>
							A real-time chat application inspired by a social app. This project focuses on delivering a
							text messaging between users. It serves as a platform for learning and showcasing skills in
							full-stack development, real-time communication, and responsive design.
						</div>
					</div>

					<div>
						<div className='title text-[16px] text-slate-500 font-[600] mt-5'>Client.</div>
						<div className='text-[14px] mt-1'>
							The frontend of the project is hosted on an <span className='font-[600]'>AWS EC2</span>{" "}
							Linux server, using React to build interactive user interfaces. Tailwind CSS is used for
							styling, offering a simple way to apply CSS. Recoil manages the app’s state, making it easy
							to handle shared data. React Router controls navigation between different pages, and
							WebSockets with Socket.IO allows real-time communication between the client and server. More
							details on README file.
							<br />
							More details can be found in the project{" "}
							<a
								className='text-blue-500 font-[500] px-1'
								href='https://github.com/hanifahmadov/chatter'
								target='_black'
							>
								README
								<span className='transform rotate-45 inline-block px-[3px]'>
									<Fontawesome type={"faArrowUp"} />
								</span>
							</a>
							file.
						</div>
					</div>
					<div>
						<div className='title text-[16px] text-slate-500 font-[600] mt-5'>Server.</div>
						<div className='text-[14px] mt-1'>
							On the backend, which is hosted on a <span className='font-[600]'>Digital Ocean </span>{" "}
							CentOS server, Node.js runs the JavaScript code. Express handles routing and middleware
							tasks. MongoDB is used to store user and message data, with Mongoose helping to define data
							structures. Socket.IO also enables real-time, two-way communication between the clients and
							the server.
						</div>
					</div>

					<div className='mt-8 text-[14px]'>
						Please feel free to check out my other projects
						<span
							onClick={handleOtherProjectClick}
							className='text-blue-500 font-[500] cursor-pointer px-[5px] italic'
						>
							here.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
