import React from "react";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";
import { dots } from "../helper/fn/dots";

export const Education = () => {
	return (
		<div className='Education mt-[3rem]'>
			<div className='text-slate-500 font-[600] flex justify-between border-b-[1px] border-slate-500 pr-2'>
				<div className='title text-[16px] text-slate-500 font-[600] '>Education</div>
				<span>
					<Fontawesome type={"faBuildingColumns"} />
				</span>
			</div>

			<div className='edu-content'>
				<div className='edu-first w-full mt-[1rem]'>
					<div className='w-full flex justify-between'>
						<span className='position'>Software Engineer in Test</span>
						{dots(10, "text-gray-600")}
						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span>Sep 2023 - Feb 2024</span>
							<span>Fairfax . VA</span>
							<span>Bootcamp</span>
						</div>
					</div>
					<div className='flex flex-col justify-center items-start relative top-[-25px]'>
						<span className='pr-2 text-gray-600'>remote from</span>
						<span>Yoll Academy</span>
					</div>
				</div>

				<div className='edu-first w-full mt-[1rem]'>
					<div className='w-full flex justify-between'>
						<span className='position'>Software Engineer</span>
						{dots(10, "text-gray-600")}
						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span>Nov 2022 - March 2023</span>
							<span>Boston . MA</span>
							<span>Fellowship</span>
						</div>
					</div>
					<div className='flex flex-col justify-center items-start relative top-[-25px]'>
						<span className='pr-2 text-gray-600'>in-person from</span>
						<span>General Assembly</span>
					</div>
				</div>

				<div className='edu-first w-full mt-[1rem]'>
					<div className='w-full flex justify-between'>
						<span className='position'>Health & Safety Engineering</span>
						{dots(10, "text-gray-600")}
						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span>Sep 2007 - May 2011</span>
							<span>Baku . AZE</span>
							<span>Bachelor</span>
						</div>
					</div>
					<div className='flex flex-col justify-center items-start relative top-[-25px]'>
						<span className='pr-2 text-gray-600'>in-person from</span>
						<span>Az. Technology University</span>
					</div>
				</div>
			</div>
		</div>
	);
};


