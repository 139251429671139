import React from "react";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";
import { dots } from "../helper/fn/dots";

export const Experience = () => {
	return (
		<div className='experience mt-[3rem]'>
			<div className='text-slate-500 font-[600] flex justify-between border-b-[1px] border-slate-500 pr-2'>
				<div className='title text-[16px] text-slate-500 font-[600] '>Experience</div>
				<span>
					<Fontawesome type={"faSuitcase"} />
				</span>
			</div>

			<div
				className='experince-one py-3 px-3 mt-4 
							rounded-xl w-full flex flex-col gap-1 bg-neutral-800
							'
			>
				<div className='title w-full'>
					<div className='w-full  flex justify-between'>
						<div className='flex flex-col items-start leading-[20px]'>
							<span className='position'>Software Engineer</span>
							<span>Immersive Fellow</span>
						</div>

						{dots(10, "text-gray-600")}

						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span> Nov 2021 - March 2022</span>
							<span> Boston . MA</span>
						</div>
					</div>
					<div className='flex flex-col relative top-[0px] leading-[20px]'>
						<div className='block'>
							<span className='pr-2 text-gray-600'>at</span>General Assembly
						</div>

						<div className='pr-2 text-gray-600'>Coding Bootcamp.</div>
					</div>
				</div>

				<div className='text-slate-700 font-[900] text-[10px] py-2'>DESCRIPTION</div>

				<div className='exp-one-content'>
					<li>600-hour intensive & immersive software engineering fellowship</li>
					<li>Build frontend, backend, and full-stack applications</li>
					<li>
						<span>Incorporate latest frameworks & technologies:</span>

						<ul className='ml-8' style={{ listStyleType: "circle" }}>
							<li>React.js + Express.js</li>
							<li>MongoDB</li>
							<li>Node.js</li>
						</ul>
					</li>
					<li>Write complex, efficient algorithms in JavaScript & Python</li>
				</div>
			</div>

			<div className='experince-one py-3 px-3 mt-4 rounded-xl w-full flex flex-col gap-1 bg-neutral-800'>
				<div className='title w-full'>
					<div className='w-full  flex justify-between'>
						<span className='position'>Safety Inspector</span>
						{dots(10, "text-gray-600")}
						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span>Feb 2015 - Sep 2017</span>
							<span>Baku . Azerbaijan</span>
						</div>
					</div>
					<div className='flex flex-col justify-center items-start relative top-[-8px] '>
						<span>
							<span className='pr-2 text-gray-600'>at</span>Mashrig G. of Companies
						</span>

						<span className='pr-2 text-gray-600'>Real Estate & Housing Co.</span>
					</div>
				</div>

				<div className='text-slate-700 font-[900] text-[10px] leading-none'>DESCRIPTION</div>

				<div className='exp-one-content'>
					<li>
						Developed and assisted with the implementation of the HSE in project and installed safety
						equipments for customers residential living and work spaces for presenting safety and support
					</li>
					<li>
						Checked,maintained monthly about all installed equipment by checking detailed information with
						the database and reports
					</li>

					<li>
						Regularly inspected and maintained safety equipment, such as fire extinguishers, smoke
						detectors, and emergency exits, in all company-provided housing units
					</li>
				</div>
			</div>

			<div className='experince-one py-3 px-3 mt-4 rounded-xl w-full flex flex-col gap-1 bg-neutral-800'>
				<div className='title w-full'>
					<div className='w-full  flex justify-between'>
						<span className='position'>Coordinator</span>
						{dots(10, "text-gray-600")}
						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span>Jan 2014 - Dec 2014</span>
							<span>Baku . Azerbaijan</span>
						</div>
					</div>
					<div className='flex flex-col justify-center items-start relative top-[-8px] '>
						<span>
							<span className='pr-2 text-gray-600'>at</span>AMEC Foster Wheeler
						</span>

						<span className='pr-2 text-gray-600'>Engineering & Project Management Co.</span>
					</div>
				</div>

				<div className='text-slate-700 font-[900] text-[10px] leading-none'>DESCRIPTION</div>

				<div className='exp-one-content'>
					<li>
						Managed various support services, such as transportation, ensuring that employees had easy
						access to work and other essential amenities
					</li>
					<li>
						Handled cost-effective solutions for housing and other services, contributing to the company's
						financial efficiency while maintaining a high standard of employee satisfaction
					</li>
				</div>
			</div>

			<div className='experince-one py-3 px-3 mt-4 rounded-xl w-full flex flex-col gap-1 bg-neutral-800'>
				<div className='title w-full'>
					<div className='w-full  flex justify-between'>
						<span className='position'>Procurement Specialist</span>
						{dots(10, "text-gray-600")}
						<div className='flex flex-col items-end text-[10px] font-[600]'>
							<span>Apr 2013 - Dec 2013</span>
							<span>Baku . Azerbaijan</span>
						</div>
					</div>
					<div className='flex flex-col justify-center items-start relative top-[-8px]'>
						<span>
							<span className='pr-2 text-gray-600'>at</span>Design Holdings
						</span>

						<span className='pr-2 text-gray-600'>Design and Decor of Interiors</span>
					</div>
				</div>

				<div className='text-slate-700 font-[900] text-[10px] leading-none'>DESCRIPTION</div>

				<div className='exp-one-content'>
					<li>
						Negotiated with suppliers and vendors to secure cost-effective contracts, resulting in
						significant cost savings for the organization
					</li>
					<li>
						Collaborated with cross-functional teams, including project managers, engineers, and contractors
					</li>
				</div>
			</div>
		</div>
	);
};
