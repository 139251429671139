// NPM Packages
import React from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "styled-components";

/* Components */

export const App = () => {
	const { sm, md } = useTheme();

	return (
		<div className='app h-full w-full bg-black flex justify-center items-center'>
			<div
				className={
					md
						? "content w-full h-full bg-black pb-[1rem] px-[0.5rem]"
						: "content w-full h-full max-w-[50rem] min-w-[50rem] bg-black pb-[1rem] px-[1rem]"
				}
			>
				<Outlet />
			</div>
		</div>
	);
};
