import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTheme } from "styled-components";
import "animate.css";

/* images */
import chatterImage from "../helper/image/chatter.png";
import tictactoeImage from "../helper/image/ttt.png";
import comingImage from "../helper/image/coming.jpg";

/* global states */
import { displayIndexDefault } from "../store/states";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";

export const Portfolio = ({ handleScroll, displayRef }) => {
	/* mobile first */
	const { sm, md } = useTheme();

	const [displayIndex, setDisplayIndex] = useRecoilState(displayIndexDefault);
	const [shake, setShake] = useState(null);

	const handleShake = () => {
		console.log("first");
		if (!shake) {
			setShake(true);

			setTimeout(() => {
				setShake(false);
			}, 1000);
		}
	};

	const navigateTicTacToe = () => {
		setDisplayIndex(1);
		handleScroll(displayRef);
	};

	const navigateChatter = () => {
		setDisplayIndex(2);
		handleScroll(displayRef);
	};

	return (
		<div className='port min-h-full'>
			<div
				className={`port-header-row 
							flex flex-col justify-start items-center bg-neutral-900  
							${sm ? "px-[0.5rem]" : "px-[1.5rem]"} 
							pt-[0.5rem] pb-[1rem] rounded-3xl
							`}
			>
				<div className='port-header text-[22px] font-[600]'>Portfolio</div>
				<div className='port-info w-full p-[0.5rem] text-[14px]'>
					Projects range from full-stack, cloud-integrated apps to lighter clients demonstrating
					implementation of algorithms or technologies that I found interesting. I spend couple hours daily
					developing new projects and improving my skillset.
				</div>
			</div>

			<div
				className={`port-project-row w-full
                            flex gap-3 flex-col justify-center items-center 
                            ${sm ? "p-2" : "p-5"}
							mt-2 rounded-3xl bg-neutral-900  
                            relative
                           
                            `}
			>
				<div
					className={`body w-full 
								flex flex-wrap gap-3 items-center 
								${sm ? "pl-2" : "pl-0"}
								
								`}
				>
					{/* Tic Tac Toe */}
					<div
						onClick={navigateTicTacToe}
						className='group project-tictactie relative flex justify-center
                                	overflow-hidden rounded-xl 
                                	transition-all ease-in-out duration-200
                                	border-[1px] border-transparent
                                	p-1 cursor-pointer
                                	hover:border-white
                                	'
					>
						<div
							className={`chatter-cover 
										${sm ? "w-[160px] h-[180px]" : "w-[140px] h-[180px]"}
                                    	overflow-hidden rounded-lg pointer-events-none
										
                                    	`}
						>
							<img src={tictactoeImage} className='object-cover w-full h-full' />
						</div>
						<div
							className='absolute bottom-2 py-1
                                    w-[130px] text-center
                                    text-[12px] text-gray-500
                                    font-[500] bg-neutral-800
                                    rounded-full pointer-events-none
                                    transition-all ease-in-out duration-200
                                    group-hover:text-white
                                    '
						>
							Tic Tac Toe
							<span
								className={`fontawesome inline-block h-full text-[12px] 
                                            pl-1 transition-transform ease-in-out duration-200
                                            text-gray-500
                                            group-hover:translate-x-1  group-hover:text-white
                                            `}
							>
								<Fontawesome type={"faArrowRight"} />
							</span>
						</div>
					</div>

					{/* Chatter */}
					<div
						onClick={navigateChatter}
						className='group project-chatter relative flex justify-center
                                overflow-hidden rounded-xl 
                                transition-all ease-in-out duration-200
                                border-[1px] border-transparent
                                p-1 cursor-pointer
                                hover:border-white
                                '
					>
						<div
							className={`chatter-cover 
								${sm ? "w-[160px] h-[180px]" : "w-[140px] h-[180px]"}
								overflow-hidden rounded-lg pointer-events-none
								
								`}
						>
							<img src={chatterImage} className='object-cover w-full h-full' />
						</div>
						<div
							className='absolute bottom-2 py-1
                                        w-[130px] text-center
                                        text-[12px] text-gray-500
                                        font-[500] bg-neutral-800
                                        rounded-full pointer-events-none
                                        transition-all ease-in-out duration-200
                                        group-hover:text-white
                                        '
						>
							Chatter
							<span
								className={`fontawesome inline-block h-full text-[12px] 
                                            pl-1 transition-transform ease-in-out duration-200
                                            text-gray-500
                                            group-hover:translate-x-1  group-hover:text-white
                                            `}
							>
								<Fontawesome type={"faArrowRight"} />
							</span>
						</div>
					</div>

					{/* InComing*/}
					<div
						onClick={handleShake}
						className=' group project-tictactie relative flex justify-center
                                overflow-hidden rounded-xl 
                                transition-all ease-in-out duration-200
                                border-[1px] border-transparent
                                p-1 cursor-not-allowed
                                hover:border-white
                                '
					>
						<div
							className={`chatter-cover 
									${sm ? "w-[160px] h-[180px]" : "w-[140px] h-[180px]"}
									overflow-hidden rounded-lg pointer-events-none
									
									`}
						>
							<img src={comingImage} className='object-cover w-full h-full' />
						</div>
						<div
							className='  absolute bottom-2 py-1
                                    w-[130px] text-center
                                    text-[12px] text-gray-500
                                    font-[500] bg-neutral-800
                                    rounded-full pointer-events-none
                                    transition-all ease-in-out duration-200
                                    '
						>
							<div className={shake ? "animate__animated animate__headShake text-white" : ""}>
								Upcoming
							</div>
							<span
								className={`hidden fontawesome inline-block h-full 
                                            text-[12px] pl-1 transition-transform duration-200 ease-in-out 
                                            group-hover:translate-x-1
                                            `}
							>
								<Fontawesome type={"faArrowRight"} />
							</span>
						</div>
					</div>

					{/* InComing*/}
					<div
						onClick={handleShake}
						className=' group project-tictactie relative hidden
								justify-center
                                overflow-hidden rounded-xl 
                                transition-all ease-in-out duration-200
                                border-[1px] border-transparent
                                p-1 cursor-not-allowed
                                hover:border-white
								
                                '
					>
						<div
							className='chatter-cover w-[140px] h-[180px]
                                    overflow-hidden rounded-lg pointer-events-none
                                   
                                    '
						>
							<img src={comingImage} className='object-cover w-full h-full' />
						</div>
						<div
							className='  absolute bottom-2 py-1
                                    w-[130px] text-center
                                    text-[12px] text-gray-500
                                    font-[500] bg-neutral-800
                                    rounded-full pointer-events-none
                                    transition-all ease-in-out duration-200
                                    '
						>
							<div className={shake ? "animate__animated animate__headShake text-white" : ""}>
								Upcoming
							</div>
							<span
								className={`hidden fontawesome inline-block h-full 
                                            text-[12px] pl-1 transition-transform duration-200 ease-in-out 
                                            group-hover:translate-x-1
                                            `}
							>
								<Fontawesome type={"faArrowRight"} />
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
