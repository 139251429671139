import React from "react";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";
import { dots } from "../helper/fn/dots";

export const Resume_Skills = () => {
	return (
		<div className='experience mt-[3rem]'>
			<div className='text-slate-500 font-[600] flex justify-between border-b-[1px] border-slate-500 pr-2'>
				<div className='title text-[16px] text-slate-500 font-[600] '>Skills</div>
				<span>
					<Fontawesome type={"faPersonBiking"} />
				</span>
			</div>

			<div className='skills-content flex flex-col mt-2'>
				<div className='w-full flex justify-between'>
					<span className='position'>Javascript</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Advanced</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>AWS</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Cloud Computing</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Serverless App.</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Interm</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>APIs</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>WebSockets</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>React.js</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Advanced</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>MongoDB</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Node.js</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Advanced</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Full-Stack Dev</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Front-End Dev</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Back-End Dev</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Python</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Interm</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>SQL</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Interm</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Tailwind CSS</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Express.js</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>Algorithms</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>Interm</span>
				</div>
				<div className='w-full flex justify-between'>
					<span className='position'>State Management</span>
					{dots(20, "text-gray-700")}
					<span className='text-[12px] font-[500]'>High</span>
				</div>
			</div>
		</div>
	);
};


