import { atom } from "recoil";

/**
 *  device default states
 */
export const deviceDefault = atom({
	key: "deviceDefault",
	default: {
		xsm: null /* > 360 */,
		sm: null /* > 576*/,
		md: null /* > 768 */,
		lg: null /* > 1024 */,
		xlg: null /*  > 1440 */,
	},
});

/*  active index */
export const activeIndexDefault = atom({
	key: "activeIndexDefault",
	default: false,
});

/*  active index */
export const displayIndexDefault = atom({
	key: "displayIndexDefault",
	default: 0,
});
