import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

export const FlashlightText = () => {
	const [animationComplete, setAnimationComplete] = useState(false);

	const letters = [
		"w",
		"h",
		"o",
		"n",
		"e",
		"v",
		"e",
		"r",
		"s",
		"t",
		"o",
		"p",
		"s",
		"l",
		"e",
		"a",
		"r",
		"n",
		"i",
		"n",
		"g",
		".",
	];

	const containerVariants = {
		animate: {
			transition: {
				staggerChildren: 0.05,
			},
		},
	};

	const letterVariants = {
		initial: { opacity: 0 },
		animate: {
			opacity: [0.4, 1, 0.4],
			color: "#64748b",
			transition: {
				duration: 1,
				ease: "easeInOut",
				repeat: 0,
				repeatType: "mirror",
			},
		},
	};

	return (
		<motion.div
			className={`text-[14px] font-[500] flex opacity-100`}
			variants={containerVariants}
			initial='initial'
			animate='animate'
			onAnimationComplete={() => {
				setAnimationComplete(true);
			}}
		>
			{letters.map((letter, index) => (
				<motion.span
					key={index}
					variants={letterVariants}
					className={`${index === 3 || index === 8 || index === 13 ? "pl-1" : ""}`}
				>
					{letter}
				</motion.span>
			))}
		</motion.div>
	);
};
