import React from "react";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";

export const Contact_Info = () => {
	return (
		<div className='mt-[3rem]'>
			<div className='text-[14px] text-slate-500 font-[600] flex justify-between border-b-[1px] border-slate-500 pr-2'>
				<div className='title text-[16px] text-slate-500 font-[600] '>Contact Information</div>
				<span>
					<Fontawesome type={"faAddressCard"} />
				</span>
			</div>

			<div className='flex gap-20 mt-3'>
				<div className='left flex gap-2 flex-col'>
					<div>
						<span>
							<Fontawesome type={"faLocationDot"} />
						</span>
						<span className='pl-2 text-[12px]'>Boston . MA</span>
					</div>

					<div>
						<span>
							<Fontawesome type={"faMobileScreenButton"} />
						</span>
						<span className='pl-2 text-[12px]'>669 . 200 . 8599</span>
					</div>

					<div>
						<span>
							<Fontawesome type={"faEnvelope"} />
						</span>

						<span className='pl-2 text-[12px]'>h.ehmedov @ gmail.com</span>
					</div>
				</div>
				<div className='right flex flex-col gap-2'>
					<div>
						<span>
							<Fontawesome type={"faGithub"} />
						</span>

						<span className='text-blue-500 pl-2 text-[12px]'>
							<a href='https://github.com/hanifahmadov' target='_blank'>
								hanifahmadov
							</a>
						</span>
					</div>

					<div>
						<span>
							<Fontawesome type={"faLinkedinIn"} />
						</span>
						<span className='text-blue-500 pl-2 text-[12px]'>
							<a href='https://www.linkedin.com/in/hanifahmadov/' target='_blank'>
								hanifahmadov
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
