import React from "react";
import { useTheme } from "styled-components";

export const About = () => {
	/* mobile-first */
	const { sm, md } = useTheme();

	return (
		<div
			className={`flex flex-col justify-start items-center 
						bg-neutral-900 rounded-3xl 
						${md ? "px-4" : "px-10 "}
						pb-5 pt-3
						`}
		>
			<div className='about-header text-[22px] font-[600]'>About me</div>
			<div className='about-content w-full flex gap-10 flex-col justify-start'>
				<div className='phrase-one mt-[5px]'>
					<div className='title text-[16px] text-slate-500 font-[600]'>Software Engineer.</div>
					<div className='text-[14px]mt-[2px]'>
						I am a graduate of{" "}
						<a
							href='https://generalassemb.ly/?&utm_source=google&utm_medium=paid-search-bra&utm_campaign=TS:TX:BRA:BOS:BR:GeneralAssembly&gclsrc=aw.ds&gad_source=1&gclid=Cj0KCQjw9Km3BhDjARIsAGUb4nzvjyVeW5K_qoMkXGlOSSojjg3cJJDMXppzDBWB6rij5uA2k2fjGjwaAjgUEALw_wcB'
							target='_black'
							className='text-blue-500 px-1 italic'
						>
							General Assembly
						</a>
						Boston with a
						<a
							href='https://drive.google.com/file/d/1H9XU39QfavW7woTM4wuYR8ziY6g0ZcbC/view?usp=sharing'
							target='_black'
							className='text-blue-500 px-1 italic'
						>
							certificate
						</a>{" "}
						in Full Stack Software Engineering. Throughout the course, I developed strong skills in Python
						and JavaScript, completed assignments that involved analyzing and implementing algorithms & data
						structures, effectively using and querying relational/non-relational databases, and mastering
						other foundational computer science concepts. I dedicated 500 hours to full-stack projects,
						working with UI frameworks, libraries, server-side technologies, APIs, and continuous
						integration and development practices.
					</div>
				</div>

				<div className='phrase-two'>
					<div className='title text-[16px] text-slate-500 font-[600]'>Discovering Coding Skills.</div>
					<div className='text-[14px] mt-[2px]'>
						I have a bachelor's degree in HSE - Environmental Health and Safety Control from Azerbaijan
						Technical University. After several years in education-related roles, I found myself seeking new
						challenges and was introduced to software engineering by a friend who works in the field of
						computer engineering. I quickly discovered a new talent and developed a passion for using that
						talent to solve interesting problems. His coding projects inspired me to dive into the world of
						programming.
					</div>
				</div>

				<div className='phrase-three'>
					<div className='title text-[16px] text-slate-500 font-[600]'>Keeping up w/ new Technologies.</div>
					<div className='text-[14px] mt-[2px]'>
						I explore new technologies like Docker, Kubernetes, and pipelines, continually building new
						projects to keep my skills sharp. As a strong advocate for continuous learning, I regularly seek
						opportunities to enhance my expertise and stay updated with industry trends.
					</div>
				</div>
			</div>
		</div>
	);
};
