import React from "react";
import { useTheme } from "styled-components";

/* helper */
import { dots } from "../helper/fn/dots";

export const Skills = () => {
	/* mobile first */
	const { sm, md } = useTheme();

	return (
		<div
			className={`w-full 
						flex flex-col justify-start items-center 
						bg-neutral-900 rounded-3xl 
						${sm ? "px-3" : "px-10"}
						pb-10 pt-3
						`}
		>
			<div className='about-header text-[22px] font-[600] w-full bg-neutral-900 h-full text-center'>
				Tech Stack
			</div>
			<div className='about-content w-full flex gap-10 flex-col justify-start'>
				<div className='phrase-one mt-[5px]'>
					<div className='title text-[16px] text-slate-500 font-[600]'>Languages.</div>
					<div className='text-[14px] mt-[2px] w-full'>
						<div className='a w-full flex justify-between items-center'>
							<li className='marker:text-gray-700'>JavaScript & TypeScript </li>

							{dots(16)}

							<div className='text-[12px]'>Advanced</div>
						</div>

						<div className='a w-full flex justify-between items-center'>
							<li className='marker:text-gray-700'>Python </li>

							{dots(20)}

							<div className='text-[12px]'>Interm</div>
						</div>

						<div className='a w-full flex justify-between items-center'>
							<li className='marker:text-gray-700'>Java </li>

							{dots(20)}

							<div className='text-[12px]'>Interm</div>
						</div>
					</div>
				</div>

				<div className='phrase-one mt-[5px]'>
					<div className='title text-[16px] text-slate-500 font-[600]'>Technologies & Frameworks.</div>
					<div>I have implemented each of the following at least once.</div>

					<div className='phrase-one mt-[20px]'>
						<div className='title text-[16px] text-slate-500 font-[600]'>Cloud computing.</div>

						<div className='text-[14px] mt-[2px] w-full'>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Heroku</li>

								{dots(21)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='w-full flex flex-col justify-start'>
								<div className='w-full flex justify-between items-center'>
									<li className='marker:text-gray-700'>Cloud MongoDB</li>
									{dots(21)}
									<div className='text-[12px]'>Interm</div>
								</div>

								<span className='pl-5'>
									{" "}
									<span className='text-slate-500'> & </span>Postgres database
								</span>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Digital Ocean & Droplets</li>

								{dots(14)}

								<div className='text-[12px]'>Interm</div>
							</div>

							<div className='text-[14px] mt-[2px] w-full'>
								<div className='a w-full flex justify-between items-center'>
									<li className='marker:text-gray-700'>AWS </li>

									{dots(20)}

									<div className='text-[12px]'>Interm</div>
								</div>
								<div className='aws-lists'>
									<ul className='pl-8' style={{ listStyleType: "circle" }}>
										<li className='marker:text-gray-700'>S3 Buckets</li>
										<li className='marker:text-gray-700'>EC2 instances</li>
										<li className='marker:text-gray-700'>API Gateways</li>
										<li className='marker:text-gray-700'>Lambda Function (Serverless)</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className='phrase-one mt-[20px]'>
						<div className='title text-[16px] text-slate-500 font-[600]'>APIs & Full-stack.</div>

						<div className='text-[14px] mt-[2px] w-full'>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Design & Development</li>

								{dots(17)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>3rd Party App integration</li>

								{dots(16)}

								<div className='text-[12px]'>Interm</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>WebSockets & Socket.io</li>

								{dots(16)}

								<div className='text-[12px]'>Interm</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Node.js & Express</li>

								{dots(18)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>SQL & Postgres</li>

								{dots(18)}

								<div className='text-[12px]'>Interm</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Algoritms & Data structure</li>

								{dots(14)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>React.js</li>

								{dots(20)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Redux & Recoil State Management</li>

								{dots(14)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Tailwind & Bootstrap & Styled </li>

								{dots(15)}

								<div className='text-[12px]'>Advanced</div>
							</div>
						</div>
					</div>

					<div className='phrase-one mt-[20px]'>
						<div className='title text-[16px] text-slate-500 font-[600]'>Soft skills.</div>

						<div className='text-[14px] mt-[2px] w-full'>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Written & Communication</li>

								{dots(13)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>
									Independently motivated & Passionate about Software
								</li>

								{dots(8)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Agile development & scrum</li>

								{dots(15)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Version contorl (Git)</li>

								{dots(17)}

								<div className='text-[12px]'>Advanced</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700 text-[12px]'>
									Scaled development decisions (<span className="text-[10px]">Application Architecture & Design</span>)
								</li>

								{dots(4)}

								<div className='text-[10px]'>Interm</div>
							</div>
							<div className='a w-full flex justify-between items-center'>
								<li className='marker:text-gray-700'>Individual & team-based software development</li>

								{dots(9)}

								<div className='text-[12px]'>Interm</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
