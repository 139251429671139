import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { useInView } from "framer-motion";
import { useTheme } from "styled-components";

/* images */
import image from "../helper/image/_MYP3932.jpg";

/* states */
import { activeIndexDefault, displayIndexDefault } from "../store/states";

/* helpter */
import { FlashlightText } from "./FlashlightText";
import { Navbar } from "./Navbar";
import { About } from "./About";
import { Display } from "./Display";
import { Skills } from "./Skills";
import { Resume } from "./Resume";

export const Intro = () => {
	/* mobile first */
	const { sm, md } = useTheme();

	const [activeIndex, setActiveIndex] = useRecoilState(activeIndexDefault);
	const [displayIndex] = useRecoilState(displayIndexDefault);

	const introRef = useRef(null);
	const aboutRef = useRef(null);
	const porfolioRef = useRef(null);
	const stackRef = useRef(null);
	const contactRef = useRef(null);
	const displayRef = useRef(null);

	const introInView = useInView(introRef, { amount: 0.1 });
	const aboutInView = useInView(aboutRef, { amount: 0.5 });
	const displayInView = useInView(displayRef, { amount: 0.5 });
	const stackInView = useInView(stackRef, { amount: 0.4 });
	const contactInView = useInView(contactRef, { amount: 0.2 });

	const handleScroll = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};

	useEffect(() => {
		console.log("displayIndex", displayIndex);
		/* order is matter */
		if (contactInView) {
			setActiveIndex(3);
			return;
		}

		if (stackInView) {
			console.log("alooo");
			setActiveIndex(2);
			return;
		}

		if (displayInView) {
			setActiveIndex(1);
			return;
		}

		if (aboutInView) {
			setActiveIndex(0);
			return;
		}

		setActiveIndex(null);
	}, [aboutInView, displayInView, stackInView, contactInView, displayIndex]);

	return (
		<motion.div
			className='into_main h-[100svh] w-full
                        flex flex-col justify-center items-center
						relative
                        '
		>
			<div
				className='into_scroll
							h-[100svh] w-full overflow-scroll scrollbar-none 
							text-white

							'
			>
				<div
					ref={introRef}
					className='intro_home
						w-full 
						h-full
                        text-white 
                        flex flex-col justify-center items-center
						'
				>
					<div className='flex flex-col justify-center items-center'>
						<div className='w-[90px] h-[90px] rounded-full overflow-hidden'>
							<img src={image} className='object-cover w-full h-full' />
						</div>

						<div className='full-name flex gap-2 text-[26px] font-[600] mt-3'>
							<div className='f-name'>Hanifa</div>
							<div className='l-name'>Ahmadov</div>
						</div>

						<div className='text-white  flex flex-col text-center mb-0'>
							<span className='text-[16px] font-[600]'>Software Engineer </span>
							<div className='text-[14px] font-[600] text-gray-700'>
								<FlashlightText />
							</div>
						</div>

						{/* this  */}
						<div className={`${introInView ? "sticky top-0 z-20 w-full" : "fixed top-0 z-20 w-full"}`}>
							<Navbar handleScroll={handleScroll} refs={{ aboutRef, displayRef, stackRef, contactRef }} />
						</div>
					</div>
				</div>

				<div
					ref={aboutRef}
					className={`w-full min-h-full flex justify-center items-center 
								${sm ? "pt-[3rem]" : "pt-[4rem]"}
								`}
				>
					<About />
				</div>

				<div
					ref={displayRef}
					className={`w-full min-h-full flex justify-center ${sm ? "items-start" : "items-center"}
								${sm ? "pt-[3rem]" : "pt-[4rem]"}
								`}
				>
					<Display handleScroll={handleScroll} displayRef={displayRef} />
				</div>

				<div
					ref={stackRef}
					className={`w-full min-h-full flex justify-center ${sm ? "items-start" : "items-center"}
								${sm ? "pt-[3rem]" : "pt-[4rem]"}
								${displayIndex === 0 ? "block" : "hidden"}
								`}
				>
					<Skills />
				</div>

				<div
					ref={contactRef}
					className={`w-full min-h-full flex justify-start ${sm ? "items-start" : "items-center"}
								${sm ? "pt-[3rem]" : "pt-[4rem]"}
								${ displayIndex === 0 ? "block" : "hidden"}
								`}
				>
					<Resume />
				</div>
			</div>
		</motion.div>
	);
};
