import React from "react";
import { useRecoilState } from "recoil";
import { useTheme } from "styled-components";

/* images */
import tictactoeImage from "../helper/image/ttt.png";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";

/* global states */
import { displayIndexDefault } from "../store/states";

export const TicTacToe = ({ handleScroll, displayRef }) => {
	/* mobile first */
	const { sm, md } = useTheme();

	const [displayIndex, setDisplayIndex] = useRecoilState(displayIndexDefault);

	const handleNavigate = () => {
		setDisplayIndex(0);
		handleScroll(displayRef);
	};

	const handleBackRoute = () => {
		setDisplayIndex(0);
		handleScroll(displayRef);
	};

	return (
		<div
			className='h-full  w-full 
                        rounded-3xl 
                        flex gap-2 flex-col justify-center items-center
                        '
		>
			<div
				className='ttt-header w-full
                            text-[18px] font-[600] bg-neutral-900 
                            flex justify-center items-center
                            rounded-3xl p-1 relative sticky top-0 z-1
                            '
			>
				<span
					onClick={handleNavigate}
					className='absolute left-5 cursor-pointer 
                                text-gray-500 hover:text-white
                                pr-3
                                '
				>
					<Fontawesome type='faArrowLeft' />
				</span>
				Tic Tac Toe
			</div>

			<div
				className={`ttt-body bg-neutral-900 w-full pt-5 pb-5
                            flex flex-col justify-center items-center
							rounded-3xl
							${sm ? "px-3" : "px-10"}

                            `}
			>
				<div className='ttt-image flex gap-2 flex-col justify-center items-center'>
					<div
						className={` ${sm ? "w-[95%]" : "w-[90%]"}
									bg-neutral-800 rounded-3xl
                                    flex justify-between items-center px-4 py-1
                                    `}
					>
						<div className='github text-[12px] font-[500] text-blue-500 cursor-pointer'>
							<span className='pr-1'>
								<Fontawesome type={"faGithub"} />
							</span>
							<a
								className='font-[500] text-white hover:text-blue-500 
                                            transition-all ease-in-out
                                            '
								href='https://github.com/hanifahmadov/tic-tac-toe'
								target='_blank'
							>
								Github
							</a>
						</div>

						<div className='github text-[12px] font-[500] text-blue-500 cursor-pointer'>
							<span className='pr-1'>
								<Fontawesome type={"faLink"} />
							</span>
							<a
								className='font-[500] text-white hover:text-blue-500 
                                            transition-all ease-in-out
                                            '
								href='https://tictactoe.hanifahmadov.site/'
								target='_blank'
							>
								Demo
							</a>
						</div>
					</div>
 
 
					<div
						className={` ${sm ? "w-[95%]" : "w-[90%]"} 
									rounded-xl overflow-hidden
									`}
					>
						<img src={tictactoeImage} className='w-full h-full object-fit' />
					</div>
				</div>

				<div className='ttt-text-content w-full mt-5'>
					<div>
						<div className='title text-[16px] text-slate-500 font-[600]'>First Assignment.</div>
						<div className='text-[14px] mt-1'>
							Our first assigned project at the General Assembly software engineering bootcamp program was
							to develop a tic-tac-toe application that connected to an authentication API created by GA.
							I went above and beyond by implementing all the requirements in addition to creating a game
							mode allowing users to play against the computer that was initially powered by my JavaScript
							implementation of the minimax decision-tree algorithm and the alpha-beta pruning method
							running on the client`s computer.
						</div>
					</div>

					<div className='mt-8 text-[14px]'>
						Please feel free to check out my other projects
						<span
							onClick={handleBackRoute}
							className='text-blue-500 font-[500] cursor-pointer px-[5px] italic'
						>
							here.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
