import React, { useRef } from "react";
import { Portfolio } from "./Portfolio";
import { useRecoilState } from "recoil";

/* global states */
import { displayIndexDefault } from "../store/states";

/* helper */
import { TicTacToe } from "./TicTacToe";
import { Chatter } from "./Chatter";

/* helpers */

export const Display = ({ handleScroll, displayRef }) => {
	/* global display states */
	const [displayIndex, setDisplayIndex] = useRecoilState(displayIndexDefault);

	/* navigate to the top when visiting projects */

	return (
		<div className='flex flex-col justify-center items-center w-full h-full mb-[1rem]'>
			<div
				className='h-full w-full 
                            flex flex-col justify-center items-center
                            '
			>
				{displayIndex == 0 && (
					<div className='w-full min-h-full flex flex-col justify-center items-center'>
						<Portfolio handleScroll={handleScroll} displayRef={displayRef} />
					</div>
				)}

				{displayIndex == 1 && (
					<div className='w-full h-full'>
						<TicTacToe handleScroll={handleScroll} displayRef={displayRef} />
					</div>
				)}

				{displayIndex == 2 && (
					<div className='w-full min-h-full'>
						<Chatter handleScroll={handleScroll} displayRef={displayRef} />
					</div>
				)}
			</div>
		</div>
	);
};
