import React from "react";

/* helper */
import { Fontawesome } from "../helper/fontawesome/Fontawesome";

export const Reference = () => {
	return (
		<div className='referecnce mt-[3rem]'>
			<div className='text-slate-500 font-[600] flex justify-between border-b-[1px] border-slate-500 pr-2'>
				<div className='title text-[16px] text-slate-500 font-[600] '>Reference</div>
				<span>
					<Fontawesome type={"faUserPen"} />
				</span>
			</div>

			<div className='mt-3'>
				<span className='pr-2 relative top-[-5px]'>
					<Fontawesome type={"faQuoteLeft"} />
				</span>
				Hanifa is a dedicated and motivated employee. During his tenure with us he performed day to day
				functions as tech support and after a few short weeks was up to speed with our protocols. He was tasked
				with some side projects to self manage during his time here and he exceeded our expectations in that
				capacity. One project designated to him was development of a wiki site for common user support tasks.
				The guides and tutorials he prepared were well done and very professional and are still in use today. We
				certainly miss his friendly spirit in our office but know he will do well and be a valuable asset to any
				employer who seeks him out.{" "}
				<span className='pr-2'>
					<Fontawesome type={"faQuoteRight"} />
				</span>
			</div>

			<div className='referred by mt-2 text-blue-800 font-[600]'>
				<span className='mr-2'>
					<Fontawesome type={"faPenNib"} />
				</span>
				<span className='text-blue-800 font-[600]'>
					<a href='https://www.linkedin.com/in/aykhan-yusifov/'>Aykhan Yusifov.</a>
				</span>
			</div>
		</div>
	);
};
