import React, { useState } from "react";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { useTheme } from "styled-components";

/* global state */
import { activeIndexDefault, displayIndexDefault } from "../store/states";

export const Navbar = ({ handleScroll, refs }) => {
	/* mobile-first */
	const { sm, md } = useTheme();

	const [activeIndex, setActiveIndex] = useRecoilState(activeIndexDefault);
	const [displayIndex, setDisplayIndex] = useRecoilState(displayIndexDefault);

	const handleClick = (e) => {
		const id = Number(e.target.id);

		//
		// Scroll to the appropriate section based on the clicked link
		switch (id) {
			case 0:
				handleScroll(refs.aboutRef);
				setDisplayIndex(0);
				break;
			case 1:
				handleScroll(refs.displayRef);
				setDisplayIndex(0);
				break;
			case 2:
				handleScroll(refs.stackRef);
				break;
			case 3:
				handleScroll(refs.contactRef);
				break;
			default:
				break;
		}
	};
	return (
		<div
			className='navbar w-full h-[3rem] 
                flex justify-center items-center 
                absolute top-0 bg-black
                '
		>
			<div
				className='text-white flex justify-start items-start
                    rounded-full bg-black
                    border-[1px] border-neutral-800 
                    text-[12px] font-sans relative z-10 
                    '
			>
				<motion.div
					className='absolute bg-neutral-900 rounded-full z-1'
					initial={false}
					animate={
						activeIndex == null
							? { opacity: 0 }
							: {
									opacity: 1,
									x: sm ? activeIndex * 4 + "rem" : activeIndex * 5 + "rem",
							  }
					}
					transition={{ type: "spring", stiffness: 300, damping: 30 }}
					style={{
						width: sm ? "4rem" : "5rem",
						inset: 0,
						border: "2px solid black",
					}}
				/>

				<div
					id='0'
					onClick={handleClick}
					className={`${activeIndex == 0 ? "text-white" : "text-neutral-500"}
								${sm ? "min-w-[4rem] max-w-[4rem]" : "min-w-[5rem] max-w-[5rem]"}
                        		transition ease-in-out duration-200
                        		cursor-pointer text-center font-[500] 
                        		rounded-full  py-[2px] 
                        		relative z-10
                        		`}
				>
					About
				</div>

				<div
					id='1'
					onClick={handleClick}
					className={` ${activeIndex == 1 ? "text-white" : "text-neutral-500"}
								${sm ? "min-w-[4rem] max-w-[4rem]" : "min-w-[5rem] max-w-[5rem]"}
                        		transition ease-in-out duration-200
                        		cursor-pointer text-center font-[500] 
                        		rounded-full py-[2px] 
                        		relative z-10  
                        		`}
				>
					Portfolio
				</div>

				<div
					id='2'
					onClick={handleClick}
					className={` ${activeIndex == 2 ? "text-white" : "text-neutral-500"}
								${sm ? "min-w-[4rem] max-w-[4rem]" : "min-w-[5rem] max-w-[5rem]"}
                        		transition ease-in-out duration-200
                        		cursor-pointer text-center font-[500] 
                        		rounded-full py-[2px] 
                        		relative z-10 
								${displayIndex == 0 ? "block" : "hidden"}

                        `}
				>
					Skills
				</div>
				<div
					id='3'
					onClick={handleClick}
					className={` ${activeIndex == 3 ? "text-white" : "text-neutral-500"}
								${sm ? "min-w-[4rem] max-w-[4rem]" : "min-w-[5rem] max-w-[5rem]"}
                        		transition ease-in-out duration-200
                        		cursor-pointer text-center font-[500] 
                        		rounded-full py-[2px] 
                        		relative z-10  
								${displayIndex == 0 ? "block" : "hidden"}
                        		`}
				>
					Resume
				</div>
			</div>
		</div>
	);
};
