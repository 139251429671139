/* NPM packages */
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "styled-components";
import { useRecoilState } from "recoil";

/* global states */
import { deviceDefault } from "../store/states";

/* helpers */

export const AppLayout = () => {
	/**
	 *  device state and
	 *  parse into regular to me modified
	 */
	let [device, setDevice] = useRecoilState(deviceDefault);
	device = JSON.parse(JSON.stringify(device));

	/* small screen */
	let sm = useMediaQuery({
		query: "(max-width: 576px)",
	});

	/* medium screen */
	let md = useMediaQuery({
		query: "(max-width: 768px)",
	});

	/**
	 *  adjust the sizing
	 *  sm 576
	 *  md 768
	 */
	useEffect(() => {
		setDevice({
			...device,
			sm,
			md,
		});
	}, [sm, md]);

	return (
		<ThemeProvider theme={{ sm, md }}>
			<div
				className='app h-[100svh] w-[100svw] 
                            flex justify-center items-center
                            fixed inset-0 bg-black
                        '
			>
				{/* <SignsError />
				<SignupSuccess />
				<RefreshError /> */}

				<Outlet />
			</div>
		</ThemeProvider>
	);
};
