import React from "react";
import { Fontawesome } from "../helper/fontawesome/Fontawesome";
import { useTheme } from "styled-components";

/* helpers */
import { dots } from "../helper/fn/dots";
import { Reference } from "./Reference";
import { Resume_Skills } from "./Resume_Skills";
import { Education } from "./Education";
import { Experience } from "./Experience";
import { Contact_Info } from "./Contact_Info";

export const Resume = () => {
	/* mobile first */
	const { sm, md } = useTheme();

	return (
		<div
			className={`flex flex-col justify-start items-center 
						bg-neutral-900 rounded-3xl 
						${md ? "px-4" : "px-10 "}
						pb-5 pt-3
						`}
		>
			<div className='about-header text-[22px] font-[600]'>Resume</div>
			<div className='about-content w-full flex gap-10 flex-col justify-start'>
				<div className='phrase-one mt-[15px]'>
					<div className='title flex flex-col'>
						<span className=' text-[18px] font-[600] pl-[0.5px]'>Hanifa Ahmadov</span>
						<div className='title text-[14px] text-slate-500 font-[600] leading-none'>
							Software Engineer
						</div>
					</div>
					<div className='resume-content text-[14px] mt-[10px]'>
						<div className='border-l-[3px] border-slate-500 pl-3 rounded-sm'>
							I'm a new software engineer interested in backend development and data science. I graduated
							from Azerbaijan Technology University with a degree in Health and Safety, and spent several
							years in various roles at on-off shore positions. Formerly a fellow in the Immersive
							Software Engineering course at General Assembly, I am committed to pursuing my passion for
							software.
						</div>

						<Contact_Info />
						<Experience />
						<Education />
						<Resume_Skills />
						<Reference />
					</div>
				</div>
			</div>
		</div>
	);
};
